import { useState, useEffect, useRef } from "react";
import {
	Card,
	CardContent,
	CardHeader,
	Typography,
	Button,
	Grid,
	Snackbar,
	Alert,
	AlertTitle,
} from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useUser } from "../context/UserContext";
// import WebsocketClientUSerStatus from "../services/WebsocketClientUSerStatus";
import { format } from "date-fns";
import i18n from "../l10n/strings.json";
import Device from "../images/NH-Device.png";
import Device1 from "../images/Lutefish_Sideview.png";
import Device2 from "../images/Lutefish_Topview.png";
import Online from "../images/online.svg";
import Offline from "../images/offline.svg";
// import { useAlertStatus } from "../context/AlertStatusContext";
import RemoveDeviceSetting from "./RemoveDeviceSetting";
import DeviceImg from "../images/device-img.png";
import CardMedia from "@mui/material/CardMedia";
import { DeviceHistoryContainer } from "../components/DeviceHistoryContainer";
import MyActivitiesSidePanel from "../components/Social/myActivitiesSidePanel";
// import CenterCircularProgress from "../components/CenterCircularProgress";
import SuccessIcon from "../images/tick-square.svg";
import ErrorIcon from "../images/danger.svg";
import { useAdminContext } from "../components/AdminDashboard/ManageUserDashBoard/UserDetailsProvider";

import WebsocketClientUSerStatus from "../services/WebsocketClientUSerStatus";

const DeviceSetting = () => {
	const user = useUser();
	const location = useLocation();
	const navigate = useNavigate();
	// const { updateAlertStatus } = useAlertStatus();

	const token = localStorage.getItem("sessionId");

	const [activeDevice, setActiveDevice] = useState<any>({});
	// const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
	const [deviceStatus, setDeviceStatus] = useState(false);
	const [loading, setLoading] = useState(true);
	const timeoutRef = useRef<any>(undefined);
	const [deviceHistory, setDeviceHistory] = useState<any>([]);

	const [showAlert, setShowAlert] = useState(false);
	const [alertMsg, setAlertMsg] = useState("");
	const [alertStatus, setAlertStatus] = useState(false);

	const { adminSetUserId } = useAdminContext();
	const authorities = user.authorities.map(
		(authority: { name: string }) => authority.name
	);
	useEffect(() => {
		const fetchhDeviceOf = adminSetUserId ? adminSetUserId.id : user.id;
		const fetchApi =
			adminSetUserId && authorities.includes("ROLE_ADMIN")
				? `user-devices/${adminSetUserId.userUuid}`
				: location.state?.userId
				? `user-devices/${location.state?.userObject?.user?.userUuid}`
				: "active-user-device";
		fetch(`${process.env.REACT_APP_BASE_URL}/api/${fetchApi}`, {
			method: "GET",
			headers: {
				"Content-Type": "application/json",
				Authorization: `Bearer ${token}`,
			},
		})
			.then((res) => {
				if (res.status > 400) {
					navigate(`/home/settings/deviceSetup/device`);
				} else {
					return res.json();
				}
			})
			.then((data) => {
				if (data !== null) {
					setActiveDevice(data);
					handlePingDevice(data);
				} else {
					// navigate(`/home/settings/deviceSetup/add`);
				}
			});

		getDeviceHistory();

		WebsocketClientUSerStatus.on("ping_device", onMessageReceived);
		return () => {
			WebsocketClientUSerStatus.off("ping_device", onMessageReceived);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	const getDeviceHistory = () => {
		const validId =
			adminSetUserId && authorities.includes("ROLE_ADMIN")
				? adminSetUserId.id
				: location.state?.userId
				? location.state?.userId
				: user.id;
		fetch(
			`${process.env.REACT_APP_BASE_URL}/api/user-devices/get-devices-by-user`,
			{
				method: "POST",
				headers: {
					"Content-Type": "application/json",
					Authorization: `Bearer ${token}`,
				},
				body: JSON.stringify([
					{
						userId: validId,
					},
				]),
			}
		)
			.then((res) => {
				if (res.status > 400) {
					navigate(`/home/settings/deviceSetup/device`);
				} else {
					return res.json();
				}
			})
			.then((data) => {
				if (data !== null) {
					setDeviceHistory(data);
				}
			});
	};

	const handlePingDevice = (devicedata: any) => {
		// updateAlertStatus(true, true, i18n.settings.deviceSetup.pingInprocess);
		setShowAlert(true);
		setAlertStatus(true);
		setAlertMsg(i18n.settings.deviceSetup.pingInprocess);
		let message = {
			action: "ping_device",
			timestamp: new Date().toISOString(),
			created_for: devicedata.deviceId,
			created_by: user.id,
		};
		WebsocketClientUSerStatus.handlePingDevice(message);
		timeoutRef.current = setTimeout(() => {
			setLoading(false);
			handlePingDeviceResponse(false);
		}, 10000);
	};

	const handlePingDeviceResponse = (status: boolean) => {
		if (loading) {
			const showAlert = (status: boolean, message: string) => {
				setShowAlert(true);
				setAlertStatus(status);
				setAlertMsg(message);
			};
			if (status) {
				showAlert(true, i18n.settings.deviceSetup.pingSuccess);
			} else if(!WebsocketClientUSerStatus.isWebsocketClientConnected()){
				showAlert(false, i18n.settings.deviceSetup.wsError);
			}else{
				showAlert(false, i18n.settings.deviceSetup.pingError);
			}
		}
	};

	const onMessageReceived = (responseMsg: any) => {
		if (responseMsg.action === "ping_device") {
			setLoading(false);
			if (responseMsg.metadata.ping_status === "ONLINE") {
				setDeviceStatus(true);
				globalThis.clearTimeout(timeoutRef.current);
				handlePingDeviceResponse(true);

				// Update version if ping status is online
				setActiveDevice((prevDevice: any) => ({
					...prevDevice,
					version: responseMsg.version,
				}));
			} else {
				setDeviceStatus(false);
				globalThis.clearTimeout(timeoutRef.current);
				handlePingDeviceResponse(false);
			}
		}
	};

	const handleAlertClose = () => {
		setShowAlert(false);
	};
	return (
		<>
			<Grid container>
				{showAlert && (
					<Snackbar
						sx={{
							"&.MuiSnackbar-anchorOriginTopCenter": {
								top: "70px",
							},
						}}
						anchorOrigin={{ vertical: "top", horizontal: "center" }}
						open={showAlert}
						autoHideDuration={6000}
						onClose={handleAlertClose}
					>
						<Alert
							onClose={handleAlertClose}
							icon={
								alertStatus ? (
									<img src={SuccessIcon} alt="error-icon" />
								) : (
									<img
										src={ErrorIcon}
										alt="error-icon"
										style={{ marginRight: "10px" }}
									/>
								)
							}
							sx={{
								backgroundColor: "#FFFFFF",
								boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)",
								borderRadius: "6px",
								fontSize: "1.125rem",
								fontWeight: "500",
								color: "black",
							}}
						>
							<AlertTitle
								sx={{
									fontSize: "1.125rem",
									fontWeight: "500",
									color: "#000000",
									display: "flex",
									margin: "auto",
									padding: "5px",
									justifyContent: "center !important",
								}}
							>
								{alertMsg}
							</AlertTitle>
						</Alert>
					</Snackbar>
				)}
				<Grid item={true} xs={12} className="centerPanelSocial">
					<Card>
						{activeDevice ? (
							<>
								<Grid container={true} xs={12}>
									<Grid item={true} xs={12}>
										<CardHeader
											className="card-title-custom-size"
											title='Active Device'
											sx={{
												margin: "16px",
												padding: 0,
												fontSize:'24px',
											}}
										></CardHeader>
									</Grid>
								</Grid>

								<Grid item={true} xs={12}>
									<CardContent
										sx={{
											// marginTop: "27px",
											padding: "0px 0px 0px 0px",
											display: "flex",
										}}
									>
										<Grid
											container={true}
											xs={4}
											style={{
												display: "flex",
												flexDirection: "column",
												alignItems: "center",
												justifyContent: "space-around",
											}}
										>
											<Grid item={true}>
												{/* <Typography>
													<img
														src={Device1}
														width="296px"
														height="171px"
														alt="device"
													/>
												</Typography> */}
											</Grid>
											<Grid item={true}>
												<Typography>
													<img
														src={Device2}
														width="262px"
														height="171px"
														style={{marginBottom: "57px"}}
														alt="device"
													/>
												</Typography>
											</Grid>
										</Grid>

										<Grid
											container={true}
											xs={4}
											style={{
												display: "flex",
												flexDirection: "column",
												paddingLeft: "60px",
											}}
										>
											<Grid item={true}>
												<Typography
													className="typography-field-custom-font-size"
													sx={{
														color: "#888888",
														fontWeight: "500",
													}}
												>
													{/* {i18n.settings.deviceSetup.dateAdded} */}
													Last Connected on 
												</Typography>
												<Typography
													className="typography-field-custom-font-size"
													sx={{
														color: "#000000",
														fontWeight: "600",
														marginBottom: "16px",
													}}
												>
													{activeDevice.lastOnline
														? format(
																new Date(activeDevice.lastOnline),
																"MM/dd/yyyy"
														  )
														: format(new Date(), "MM/dd/yyyy")}
												</Typography>
											</Grid>
											<Grid item={true}>
												<Typography
													className="typography-field-custom-font-size"
													sx={{
														color: "#888888",
														fontWeight: "500",
													}}
												>
													{/* {i18n.settings.deviceSetup.deviceModel} */}
													Device Status
												</Typography>
												<Typography
													className="typography-field-custom-font-size"
													sx={{
														color: "#000000",
														fontWeight: "600",
														marginBottom: "16px",
													}}
												>
															{loading ? (
														<span>Pinging device...</span>
													) : deviceStatus ? (
														<>
															<img
																style={{
																	width: "12px",
																	height: "12px",
																	paddingRight: "10px",
																}}
																src={Online}
																alt="online/offline"
															/>
															<span>Device Online</span>
														</>
													) : (
														<>
															<img
																style={{
																	width: "12px",
																	height: "12px",
																	paddingRight: "10px",
																}}
																src={Offline}
																alt="online/offline"
															/>
															<span>Device Offline</span>
														</>
													)}
													
												</Typography>		
						
											</Grid>
											<Grid item={true}>
												<Typography
													className="typography-field-custom-font-size"
													sx={{
														color: "#888888",
														fontWeight: "500",
													}}
												>
													{/* {i18n.settings.deviceSetup.dateAdded} */}
													Device Version 
												</Typography>
												<Typography
													className="typography-field-custom-font-size"
													sx={{
														color: "#000000",
														fontWeight: "600",
														marginBottom: "16px",
													}}
												>
														{activeDevice.version ? activeDevice.version : ""}
												</Typography>
											</Grid>								
																				
										</Grid>

										<Grid
											container={true}
											xs={4}
											style={{
												display: "flex",
												flexDirection: "column",
												paddingLeft: "60px",
											}}
										>
											
											<Grid item={true}>
												<Typography
													className="typography-field-custom-font-size"
													sx={{
														color: "#888888",
														fontWeight: "500",
													}}
												>
													Device ID(Serial No)
												</Typography>
												<Typography
													className="typography-field-custom-font-size"
													sx={{
														color: "#000000",
														fontWeight: "600",
														marginBottom: "16px",
													}}
												>
													{activeDevice.deviceId ? activeDevice.deviceId : ""}
												</Typography>
											</Grid>
											<Grid item={true}>
												<Typography
													className="typography-field-custom-font-size"
													sx={{
														color: "#888888",
														fontWeight: "500",
													}}
												>
													Software Version
												</Typography>
												<Typography
													className="typography-field-custom-font-size"
													sx={{
														color: "#000000",
														fontWeight: "600",
														marginBottom: "16px",
													}}
												>
													Wenger 1.34N
												</Typography>
												</Grid>	
																			
										</Grid>
										
									</CardContent>
									<Grid sx={{display:'flex', justifyContent:'end'}}>
									<Grid item={true}><Button
								//className="cancel-button button-field-custom-font-size"
								size="medium"
								variant="outlined"
								onClick={() => handlePingDevice(activeDevice)}
								sx={{
									fontSize: "0.9rem",
									lineHeight: "20px",
									border: "2px solid #0273E6",
									color: "#0273E6",
									transition: "border 0.1s",
									marginRight:'24px',
									marginBottom:'16px',
									"&:hover": {
										border: "2px solid #0273E6",
										backgroundColor: "#E9F1FF",
									},
								}}
							>
								{i18n.settings.deviceSetup.pingDevice}
							</Button></Grid>	 <Grid item={true} sx={{marginRight:'24px'}}>
							{(location.pathname === "/home/settings/deviceSetup/device" ||
								location.pathname === "/home/settings/device/device") && (
								<RemoveDeviceSetting />
							)}
						</Grid></Grid>
								</Grid>

								<Grid>
									<CardContent
										sx={{
											// marginTop: "27px",
											padding: "0px 0px 0px 0px",
											display: "flex",
											flexDirection: "column",
										}}
									></CardContent>
								</Grid>
							</>
						) : (
							<Grid container xs={12} padding="10px">
								<Grid item xs={12}>
									<Typography
										sx={{ fontSize: "24px", color: "#000", fontWeight: "500" }}
									>
										No Device Connected
									</Typography>
								</Grid>
								<CardMedia
									sx={{ height: "61px", width: "102px", margin: "20px 0" }}
									image={DeviceImg}
									// title="green iguana"
								/>
							</Grid>
						)}
					</Card>

					<Grid container xs={12} rowSpacing={2} sx={{ marginTop: 3 }}>
						<Grid item xs={12}>
							<Typography sx={{ fontSize: "24px", fontWeight: "500" }}>
								Device History
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Card
								sx={{
									padding: 2,
								}}
							>
								{deviceHistory?.filter(
									(device: any) => device.status !== "Active"
								).length === 0 && (
									<Grid
										container
										xs={12}
										sx={{ display: "flex", textAlign: "center" }}
									>
										<Grid item xs={12}>
											<Typography sx={{ fontSize: "1rem", fontWeight: "500" }}>
												No Device History Found
											</Typography>
										</Grid>
									</Grid>
								)}
								{deviceHistory
									?.filter((item: any) => item.status === "Inactive")
									.map((item: any) => (
										<div key={item.id}>
											<DeviceHistoryContainer device={item} />
										</div>
									))}
							</Card>
						</Grid>
					</Grid>
				</Grid>

				{/* <Grid item={true} xs={3}  className="rightPanelWidth">
					<Grid
						container={true}
						xs={12}

						sx={{ paddingBottom: 2 }}
					> */}
				{/* <Grid item={true} xs={12}>
							<HelpAndTips
								title={i18n.settings.deviceSetup.instructions.helpAndTips.title}
								text={i18n.settings.deviceSetup.instructions.helpAndTips.text}
								sx={{ boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)" }}
							/>
						</Grid>
						<Grid item={true} xs={12}>
							<HelpAndTips
								title={
									i18n.settings.deviceSetup.instructions.connectionProcedure
										.title
								}
								text={
									i18n.settings.deviceSetup.instructions.connectionProcedure
										.text
								}
								sx={{ boxShadow: "0px 1px 14px 0px rgba(217, 231, 255, 0.77)" }}
							/>
						</Grid> */}

				{/* <MyActivitiesSidePanel /> */}
				{/* </Grid>
				</Grid> */}
			</Grid>
		</>
	);
};

export default DeviceSetting;
